import * as _path2 from "path";
var _path = _path2;
try {
  if ("default" in _path2) _path = _path2.default;
} catch (e) {}
import * as _fs2 from "fs";
var _fs = _fs2;
try {
  if ("default" in _fs2) _fs = _fs2.default;
} catch (e) {}
var exports = {};
const {
  dirname,
  resolve
} = _path;
const {
  readdirSync,
  statSync
} = _fs;
exports = function (start, callback) {
  let dir = resolve(".", start);
  let tmp,
    stats = statSync(dir);
  if (!stats.isDirectory()) {
    dir = dirname(dir);
  }
  while (true) {
    tmp = callback(dir, readdirSync(dir));
    if (tmp) return resolve(dir, tmp);
    dir = dirname(tmp = dir);
    if (tmp === dir) break;
  }
};
export default exports;